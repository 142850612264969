<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <Form :id="anchorName" :data="data.form" :form_id="data.form_id" :title="data.name" />
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
export default {
  mixins: [dynamicBlock],
  components: {
    Form: () => import("@/components/dynamic/form/form.vue"),
  },
};
</script>